/*CSS*/
html, body {
    margin: 0px;
    padding: 0px;
    width: 100%;
    height: 100%;
    font-family: Helvetica;
    overflow: hidden;
}

#tree {
    width: 100%;
    height: 100vh;
    position: relative;
}

/*partial*/

[lcn='Consilieri-Management']>rect {
    fill: #abc8ff;
}

[lcn='Dezvoltare-Inovare']>rect {
    fill: #ff7b4a;
}

[lcn='Planificare-Organizationala']>rect {
    fill: #FF6347;
}

[lcn='Implementare-Proiecte']>rect {
    fill: #87CEFA;
}

[lcn='Suport-Proiecte']>rect {
    fill: #32CD32;
}

[lcn='Cultura-Organizationala']>rect {
    fill: #b679ef;
}


[lcn='top-management']>rect {
    fill: #f2f2f2;
}

[lcn='top-management']>text,
.assistant>text {
    fill: #aeaeae;
}

[lcn='top-management'] circle,
[lcn='assistant'] {
    fill: #aeaeae;
}

.assistant>rect {
    fill: #ffffff;
}

.assistant [data-ctrl-n-menu-id]>circle {
    fill: #aeaeae;
}

.it-team>rect {
    fill: #b4ffff;
}

.it-team>text {
    fill: #039BE5;
}

.it-team>[data-ctrl-n-menu-id] line {
    stroke: #039BE5;
}

.it-team>g>.ripple {
    fill: #00efef;
}

.hr-team>rect {
    fill: #fff5d8;
}

.hr-team>text {
    fill: #ecaf00;
}

.hr-team>[data-ctrl-n-menu-id] line {
    stroke: #ecaf00;
}

.hr-team>g>.ripple {
    fill: #ecaf00;
}

.sales-team>rect {
    fill: #ffeedd;
}

.sales-team>text {
    fill: #F57C00;
}

.sales-team>[data-ctrl-n-menu-id] line {
    stroke: #F57C00;
}

.sales-team>g>.ripple {
    fill: #F57C00;
}


